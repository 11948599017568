import './src/styles/global.scss';

import type { GatsbyBrowser } from 'gatsby';
import * as React from 'react';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
}) => {
  return <>{element}</>;
};

export const onInitialClientRender = () => {
  if (process.env.LINK_COOKIE_STYLES || process.env.GATSBY_LINK_COOKIE_STYLES) {
    const link = document.createElement('link');
    link.href = '/cookie-banner-styles.css';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }
};
